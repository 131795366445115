import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import API  from './constants'



const Requests = React.lazy(() => import('./views/Qr/Requests'));
const Products = React.lazy(() => import('./views/Qr/Products'));
const UpdateProduct = React.lazy(() => import('./views/Qr/UpdateProduct'));
const QrList = React.lazy(() => import('./views/Qr/QrList'));
const QrMerchant = React.lazy(() => import('./views/Qr/QrMerchant'));
const UpdateQr = React.lazy(() => import('./views/Qr/UpdateQr'));
const QrDetail = React.lazy(() => import('./views/Qr/QrDetail'));
const QrInvoiceList = React.lazy(() => import('./views/QrInvoice/QrInvoiceList'));
const UpdateQrInvoice = React.lazy(() => import('./views/QrInvoice/UpdateQrInvoice'));
const QrInvoiceDetail = React.lazy(() => import('./views/QrInvoice/QrInvoiceDetail'));

const MerchantAdmin = React.lazy(() => import('./views/System/MerchantAdmin'));
const MerchantReward = React.lazy(() => import('./views/System/MerchantReward'));
const Dashboard = React.lazy(() => import('./views/System/Dashboard'));
const UpdateMerchantAdmin = React.lazy(() => import('./views/System/UpdateMerchantAdmin'));
const Report = React.lazy(() => import('./views/System/Report'));
const QrWebList = React.lazy(() => import('./views/QrWeb/QrWebList'));
const CreateQrWeb = React.lazy(() => import('./views/QrWeb/CreateQrWeb'));
const UpdateQrWeb = React.lazy(() => import('./views/QrWeb/UpdateQrWeb'));
export const mainRoutes = [
  { path: '/dashboard', exact: true, name: 'Хянах самбар', component: Dashboard },
  { path: '/merchant-qr', exact: true, name: 'Байгууллагын QR', component: QrMerchant },
  { path: '/qr', exact: true, name: 'QR code', component: QrList },
  { path: '/qr/:id/edit', exact: true, name: 'Засах', component: UpdateQr },
  { path: '/qr/:id/detail', exact: true, name: 'Дэлгэрэнгүй', component: QrDetail },
  { path: '/qr-invoice-get', exact: true, name: 'QR нэхэмжлэх', component: QrInvoiceList },
  { path: '/qr-invoice-get/:id/edit', exact: true, name: 'Засах', component: UpdateQrInvoice },
  { path: '/qr-invoice-get/:id/detail', exact: true, name: 'Дэлгэрэнгүй', component: QrInvoiceDetail },
  { path: '/qr-web', exact: true, name: 'Авто', component: QrWebList },
  { path: '/qr-web/:id/edit', exact: true, name: 'Нэмэх', component: UpdateQrWeb },
  { path: '/qr-web/create', exact: true, name: 'Засах', component: CreateQrWeb },
  { path: '/shop/product/list', exact: true, name: 'Бараанууд', component: Products },
  { path: '/shop/product/list/detail/:id', exact: true, name: 'Засах', component: UpdateProduct },
  { path: '/report', exact: true, name: 'Тайлан', component: Report },

];
const merchantAdminRoutes = [
  { path: '/system/merchant', exact: true, name: 'Хэрэглэгч', component: MerchantAdmin },
  { path: '/system/merchant/edit/:id', exact: true, name: 'Засах', component: UpdateMerchantAdmin },
  { path: '/system/merchant/reportReward/:id', exact: true, name: 'Тайлан 1%', component: MerchantReward },

];
const requestRoutes = [
  { path: '/request', name: 'Хүсэлтүүд', component: Requests },
];


export const getRoutes = ()=>{
  return routes[userAuth.getRole()]
}
 const routes =  {
  'ADMIN': [
    ...merchantAdminRoutes,
    ...mainRoutes,
    ...requestRoutes,
  ],
  'QRMAN': [
    ...mainRoutes
  ],
  'SALEMAN': [
    ...mainRoutes,
    ...requestRoutes
  ]
}
export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    userAuth.is_authenticated()
      ? <Component {...props} logOut={rest.logOut} />
      : <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
  )} />
)


export const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      userAuth.is_authenticated() && restricted ?
        <Redirect to="/" />
        : <Component {...props} />
    )} />
  );
};
export const callJwtReq = async (url, params) => new Promise((resolve, reject) => {
  requestJwt(url, params).then((response) => {
    if (response.status === 401) {
      const refresh = sessionStorage.getItem('refresh');
      const jwtParams = { refresh: refresh };
      fetch(API + 'token/refresh/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jwtParams)
      }).then((tokenReq) => {
        if (tokenReq.ok) {
          tokenReq.json().then((jsonRes) => {
            sessionStorage.setItem('access', jsonRes.access)
            requestJwt(url, {
              ...params, headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jsonRes.access
              }
            }).then((response) => {
              resolve(response)
            })
          })
        }
        else {
          userAuth.signout()
        }
      })
        .catch((error) => {
          reject(error)
        });
    } else {
      resolve(response)
    }
  })
})

const requestJwt = (url, params) => new Promise((resolve, reject) => {
  fetch(url, params).then((response) => {
    resolve(response)
  })
})

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};
export const roleList = [
  {
    label: 'Admin',
    value: 'ADMIN'
  },
  {
    label: 'Qr manager',
    value: 'QRMAN'
  },
  {
    label: 'Sales manager',
    value: 'SALEMAN'
  },

]
export const userAuth = {

  getPermissions() {
    const payload = parseJwt(sessionStorage['access'])
    return payload.permissions[0]
  },
  getUsername() {
    const payload = parseJwt(sessionStorage['access'])
    return payload.merchant || ''
  },
  getRole() {
    const payload = parseJwt(sessionStorage['access'])
    return payload.role
  },
  is_authenticated() {
    return sessionStorage['access'] ? true : false
  },
  getAccess() {
    return sessionStorage['access']
  },
  authenticate(access, refresh) {

    sessionStorage.setItem('access', access)
    sessionStorage.setItem('refresh', refresh)
  }
  ,
  signout() {
    sessionStorage.removeItem('access')
    sessionStorage.removeItem('refresh')
    window.location.reload();
  }
}

export default routes;
