import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PrivateRoute ,PublicRoute} from './routes';
import 'rc-pagination/assets/index.css';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const DetailQrWeb = React.lazy(() => import('./views/QrWeb/DetailQrWeb'));
class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <PublicRoute restricted={true} exact path="/login" name="Login Page" component={Login} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route exact path="/car/:id" name="Car" render={props => <DetailQrWeb {...props} />} />
            <PrivateRoute path="/" component={DefaultLayout}  />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
